<template>
  <v-container v-if="!_.isEmpty(testEditable)">
    <!-- Banner header -->
    <Banner :title="`Proyecto de testing ${testEditable.financial_product.type}`" />

    <!-- Form profile data -->
    <v-card class="my-8 pa-5">
      <v-card-text class="pa-5">
        <v-form ref="formEditTest" v-model="formEditTestValid">
          <div class="secondary pa-4 mb-5">
            <p class="text-h6">Producto financiero</p>
            <v-text-field
              v-if="testEditable.financial_product.type === 'COOPERATIVE'"
              v-model.number="testEditable.financial_product.conditions.arrear_coeficient"
              type="number"
              label="Coeficiente de mora"
              :readonly="!editable"
              :rules="formRules.numberRulesAllowZero"
            />
            <v-text-field
              v-model.number="testEditable.financial_product.conditions.monthly_fees"
              type="number"
              label="Cuotas mensuales"
              :readonly="!editable"
              :rules="formRules.numberRules"
            />
            <v-text-field
              v-model.number="testEditable.financial_product.conditions.waiting_period"
              type="number"
              label="Meses de carencia"
              :readonly="!editable"
              :rules="formRules.numberRulesAllowZero"
            />
            <v-text-field
              v-model.number="testEditable.financial_product.conditions.monthly_interest"
              type="number"
              label="Interés mensual"
              :readonly="!editable"
              :rules="formRules.numberRulesAllowZero"
            />
            <v-text-field
              v-if="testEditable.financial_product.type === 'COOPERATIVE'"
              v-model.number="testEditable.financial_product.conditions.vat"
              type="number"
              label="IVA"
              :readonly="!editable"
              :rules="formRules.numberRulesAllowZero"
            />
            <v-text-field
              v-if="testEditable.financial_product.type === 'COOPERATIVE'"
              v-model.number="testEditable.financial_product.conditions.arrear_vat"
              type="number"
              label="IVA por mora"
              :readonly="!editable"
              :rules="formRules.numberRulesAllowZero"
            />
            <v-text-field
              v-if="testEditable.financial_product.type === 'COOPERATIVE'"
              v-model.number="testEditable.financial_product.conditions.commission"
              type="number"
              label="Comisión"
              :readonly="!editable"
              :rules="formRules.numberRulesAllowZero"
            />
            <v-text-field
              v-if="testEditable.financial_product.type === 'COOPERATIVE'"
              v-model.number="testEditable.financial_product.conditions.commission_real"
              type="number"
              label="Comisión real"
              :readonly="!editable"
              :rules="formRules.numberRulesAllowZero"
            />
          </div>
          <DatePicker
            :parent_model="testEditable.active_at"
            label="Fecha de activación"
            :clearable="false"
            :required="true"
            :readonly="!editable"
            @change="setDateOfActive"
          />
          <v-text-field
            v-model.number="testEditable.amount_credited"
            type="number"
            label="Financiado"
            :readonly="!editable"
            :rules="formRules.numberRules"
          />

          <v-card class="pa-5">
            <p class="text-h6">Inversiones</p>
            <v-row>
              <v-col cols="12" md="3" v-for="(investment, i) in testEditable.investments" :key="i" class="pa-1">
                <div class="secondary pa-4">
                  <v-toolbar dense flat class="secondary">
                    <v-toolbar-title>Inversión {{ i + 1 }}</v-toolbar-title>
                    <v-spacer />
                    <v-btn v-if="i != 0 && editable" icon elevation="2" small color="red" @click="removeInvestment(i)">
                      <v-icon small>delete</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-text-field
                    v-model.number="investment.amount"
                    type="number"
                    label="Cantidad"
                    :readonly="!editable"
                    :rules="formRules.numberRules"
                  />
                </div>
              </v-col>
            </v-row>
            <div v-if="editable" class="text-right">
              <v-btn elevation="2" small @click="addInvestment">
                <v-icon>add</v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-icon v-if="!editable && permissionsCardsActions.profileData.edit.includes(user.role)" @click="editable = true">edit</v-icon>
        <v-btn v-if="editable" text @click="(editable = false), formatFields()">Cancelar</v-btn>
        <v-btn v-if="editable" text @click="edit">Guardar</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Data table repayments -->
    <v-card class="my-8">
      <v-toolbar color="primary" dense>
        <span>Cobros</span>
        <v-spacer />
        <v-btn v-if="permissionsBannerActions.addRepayment.includes(user.role)" rounded small @click="dialogEditRepayments = true">
          Editar cobros
        </v-btn>
      </v-toolbar>
      <DataTable
        :headers="tableRepaymentsHeaders"
        :items="testEditable.repayments ? objectToArray(testEditable.repayments) : []"
        :sortBy="'paid_at'"
        :sortDesc="false"
        :hideDefaultFooter="false"
      >
        <template v-slot:[`item.amount`]="{ item }">
          {{ currency(item.amount, { fromCents: true }) }}
        </template>
        <template v-slot:[`item.paid_at`]="{ item }">
          {{ formatDateL(item.paid_at) }}
        </template>
      </DataTable>
    </v-card>

    <!-- Data table calendar payments -->
    <v-card class="my-8">
      <v-toolbar color="primary" dense>
        <span>Calendario de pagos</span>
        <v-spacer />
        <v-btn x-small @click="recalculate()">Recalcular</v-btn>
      </v-toolbar>
      <Moratoriums v-if="testEditable.calendar_payments" :project="testEditable" :action="'editTestCalendarPaymentsMoratoriums'" />
      <CalendarPayments
        v-if="testEditable.calendar_payments"
        :calendarPayments="testEditable.calendar_payments"
        :financialProductType="testEditable.financial_product.type"
      />
      <v-simple-table v-else>
        <template v-slot:default>
          <tbody>
            <tr>
              <td class="text-center text--disabled">No hay datos disponibles</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <!-- Calculated fields + Prepaid info -->
    <v-card v-if="testEditable.calendar_payments" class="secondary pa-5">
      <v-row>
        <v-col cols="12" md="6">
          <p class="mb-1"><b>Fecha de hoy: </b>{{ formatDateL(dateNowFormated()) }}</p>
          <p class="mb-1"><b>Fecha de cheque: </b>{{ formatDateL(formatDate(testEditable.active_at)) }}</p>
          <p class="mb-1"><b>Importe de cheque: </b>{{ testEditable.amount_credited }}</p>
          <p class="mb-1">
            <b>Total a devolver: </b>
            {{ currency(testEditable.amount_repaid + testEditable.amount_repaid_remaining, { fromCents: true }) }}
          </p>
          <p class="mb-1"><b>Cuotas: </b>{{ currency(testEditable.amount_repaid, { fromCents: true }) }}</p>
          <p class="mb-1"><b>Pendiente a devolver: </b>{{ currency(testEditable.amount_repaid_remaining, { fromCents: true }) }}</p>
        </v-col>
        <v-col v-if="testEditable.financial_product.type === 'COOPERATIVE'" cols="12" md="6">
          <PrepaidInfo
            :id="testEditable.id"
            :maxDate="testEditable.calendar_payments.payments[testEditable.calendar_payments.payments.length - 2].due_date"
            :actionName="'getAmountPrepaidTestCalendarPayments'"
          />
        </v-col>
      </v-row>
    </v-card>

    <!-- Dialog edit repayments -->
    <DialogBox :model="dialogEditRepayments">
      <template slot="toolbar">
        <span>Editar cobros</span>
        <v-spacer />
        <v-btn elevation="2" small @click="addRepayment">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <template slot="content">
        <v-form ref="formEditRepayments" v-model="formEditRepaymentsValid">
          <v-row>
            <v-col cols="12" md="6" v-for="(repayment, i) in repayments" :key="i" class="pa-1">
              <div class="secondary pa-4">
                <v-toolbar dense flat class="secondary">
                  <v-toolbar-title>Cobro {{ i + 1 }}</v-toolbar-title>
                  <v-spacer />
                  <v-btn icon elevation="2" small color="red" @click="removeRepayment(i)">
                    <v-icon small>delete</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-text-field v-model.number="repayment.amount" type="number" label="Cantidad" :rules="formRules.numberRules" />
                <DatePicker
                  label="Fecha del cobro"
                  :parent_model="repayment.paid_at"
                  :required="true"
                  @change="setRepaymentDate($event, i)"
                />
              </div>
            </v-col>
          </v-row>
        </v-form>
        <p v-if="repayments.length === 0" class="text-center text-subtitle-1 mb-0 mt-10">Haz click en "+" para añadir cobros</p>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('dialogEditRepayments', 'formEditRepayments')">Cerrar</v-btn>
        <v-btn text @click="editRepayments()">Guardar</v-btn>
      </template>
    </DialogBox>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import CommonMixin from "@/mixins/CommonMixin";
import _ from "lodash";
import Banner from "@/components/elements/Banner";
import DataTable from "@/components/elements/DataTable";
import CalendarPayments from "@/components/elements/CalendarPayments";
import DialogBox from "@/components/elements/DialogBox";
import DatePicker from "@/components/elements/DatePicker";
import listeners from "@/firebase/listeners";
import PrepaidInfo from "@/components/elements/project/PrepaidInfo.vue";
import Moratoriums from "@/components/elements/project/Moratoriums.vue";
import * as currency from "currency.js";

export default {
  name: "TestCalendarPayments",
  components: {
    Banner,
    DataTable,
    CalendarPayments,
    DialogBox,
    DatePicker,
    PrepaidInfo,
    Moratoriums
  },
  mixins: [FormRulesMixin, FormatDateMixin, CommonMixin],
  data() {
    return {
      // Banner actions permissions
      permissionsBannerActions: {
        addRepayment: ["SUPERADMIN", "ADMIN"]
      },

      // Repayments datatable
      tableRepaymentsHeaders: [
        { text: "Cantidad", value: "amount", sortable: false },
        { text: "Fecha del cobro", value: "paid_at" }
      ],

      // Cards actions permissions
      permissionsCardsActions: {
        profileData: {
          edit: ["SUPERADMIN", "ADMIN"]
        }
      },

      // Form edit test
      editable: false,
      formEditTestValid: false,
      test: {},
      testEditable: {},

      // Form add repayment
      formEditRepaymentsValid: false,
      repayments: [
        {
          amount: 0,
          paid_at: ""
        }
      ],

      // Dialogs
      dialogEditRepayments: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      testCalendarPayments: (state) => state.projects.testCalendarPayments
    }),
    _() {
      return _;
    },
    currency() {
      return currency;
    }
  },
  watch: {
    testCalendarPayments() {
      this.formatFields();
    },
    editable() {
      this.$store.commit("SET_EDITING", this.editable);
    }
  },
  async created() {
    this.$store.commit("SET_LOADING", true);
    await listeners.testCalendarPayments(this.$route.params.id);
    // Push back if id does not exist
    if (this._.isEmpty(this.testCalendarPayments) || this.testCalendarPayments.id !== this.$route.params.id) {
      this.$router.push("/proyectos");
      this.$store.commit("SET_LOADING", false);
      return;
    }
    this.formatFields();
    this.$store.commit("SET_LOADING", false);
  },
  methods: {
    // Format fields editables
    formatFields() {
      this.testEditable = this._.cloneDeep(this.testCalendarPayments);
      this.testEditable.amount_credited = currency(this.testEditable.amount_credited, { fromCents: true });
      this.testEditable.investments = this.objectToArray(this.testEditable.investments);
      for (const investment of this.testEditable.investments) {
        investment.amount = currency(investment.amount, { fromCents: true });
      }
      if (this.testEditable.repayments) {
        this.repayments = this.objectToArray(this.testEditable.repayments);
        for (const repayment of this.repayments) {
          repayment.amount = currency(repayment.amount, { fromCents: true });
        }
      }
    },

    // Edit test
    async edit() {
      if (this.$refs.formEditTest.validate()) {
        let testEditableFormattedData = this._.cloneDeep(this.testEditable);

        testEditableFormattedData.active_at = this.formatDateToISO8601(testEditableFormattedData.active_at);
        for (const investment of testEditableFormattedData.investments) {
          investment.amount = this.convertToInt(investment.amount);
        }
        testEditableFormattedData.investments = this.arrayInvestmentsToObject(testEditableFormattedData.investments);
        testEditableFormattedData.amount_credited = this.convertToInt(testEditableFormattedData.amount_credited);
        delete testEditableFormattedData.amount_invested;
        delete testEditableFormattedData.created_at;
        delete testEditableFormattedData.updated_at;
        delete testEditableFormattedData.calendar_payments;
        delete testEditableFormattedData.repayments;
        delete testEditableFormattedData.amount_repaid;
        delete testEditableFormattedData.amount_repaid_remaining;

        console.log(testEditableFormattedData);

        // Check if there is no change in test fields
        const notChanges = this._.isEqual(this.test, testEditableFormattedData);
        if (!notChanges) await this.$store.dispatch("projects/editTestCalendarPayments", testEditableFormattedData);

        this.formatFields();
        this.editable = false;
      }
    },

    // Edit repayments
    async editRepayments() {
      if (this.$refs.formEditRepayments.validate()) {
        let repaymentsFormattedData = this._.cloneDeep(this.repayments);
        for (const repayment of repaymentsFormattedData) {
          repayment.amount = this.convertToInt(repayment.amount);
        }
        repaymentsFormattedData = this.arrayRepaymentsToObject(repaymentsFormattedData);
        const data = {
          id: this.testEditable.id,
          repayments: repaymentsFormattedData
        };
        if (this.repayments.length === 0) delete data.repayments;

        // Check if there is no change in test fields
        const notChanges = this._.isEqual(this.test.repayments, repaymentsFormattedData);
        if (!notChanges) await this.$store.dispatch("projects/editTestCalendarPaymentsRepayments", data);

        this.formatFields();
        this.closeDialog("dialogEditRepayments", "formEditRepayments");
      }
    },

    // Set repaymentDate with the date selected in datepicker
    setRepaymentDate(value, index) {
      this.repayments[index].paid_at = value;
    },

    setDateOfActive(value) {
      this.testEditable.active_at = value;
    },

    // Transform array of investments to object valid for the endPoint
    arrayInvestmentsToObject(arr) {
      let obj = {};
      for (let i = 0; i < arr.length; i++) {
        const investment = arr[i];
        obj[`INVESTMENT_${i + 1}`] = {
          amount: investment.amount,
          investor_id: `${i + 1}`
        };
      }
      return obj;
    },

    // Transform array of repayments to object valid for the endPoint
    arrayRepaymentsToObject(arr) {
      let obj = {};
      for (let i = 0; i < arr.length; i++) {
        const repayment = arr[i];
        obj[`REPAYMENT_${i + 1}`] = {
          amount: repayment.amount,
          paid_at: this.formatDateToISO8601(repayment.paid_at)
        };
      }
      return obj;
    },

    addInvestment() {
      this.testEditable.investments.push({
        amount: 0
      });
    },

    removeInvestment(index) {
      this.testEditable.investments.splice(index, 1);
    },

    addRepayment() {
      this.repayments.push({
        amount: 0,
        paid_at: ""
      });
    },

    removeRepayment(index) {
      this.repayments.splice(index, 1);
    },

    async recalculate() {
      await this.$store.dispatch("projects/recalculateTestCalendarPayments", this.testEditable.id);
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;

      this.repayment = this.$options.data().repayment;
    }
  }
};
</script>
